@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Seaweed+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding:wght@400;700&display=swap');

body {
  background-color: #191427;
  font-family: 'Poppins', sans-serif;
}

.inputBg {
  background-color: #21183c !important;
}

.authV2leftBanner .transition-opacity {
  transition: opacity 0.5s ease-in-out;
}

.authV2leftBanner .opacity-0 {
  opacity: 0;
}

.authV2leftBanner .opacity-100 {
  opacity: 1;
}

.authV2leftBanner {
  background-image: url('./assets/png/AuthBannerV2.png');
  width: 100%;
  height: 100%;
  background-size: cover; /* or 'contain' based on your needs */
  background-repeat: no-repeat;
  background-position: center; /* or other values based on your layout */
}

.nanum-gothic-coding-regular {
  font-family: 'Nanum Gothic Coding', monospace;
  font-weight: 400;
  font-style: normal;
}

.nanum-gothic-coding-bold {
  font-family: 'Nanum Gothic Coding', monospace;
  font-weight: 700;
  font-style: normal;
}

.sidebar {
  background-color: #21183c;
  z-index: 1000;
}
.active-section {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #f2f3f5;
  /* display: none; */
}

.sidebar-icon:hover .active-section {
  background-color: red;
  display: block;
}

.react-datepicker__input-container input {
  width: 150px;
  padding: 5px;
}

.date-section
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  width: 200px;
  padding: 5px;
}

/* date picker */
@media (max-width: 768px) {
  .date-section
    .react-datepicker-wrapper
    .react-datepicker__input-container
    input {
    width: 295px !important;
    padding: 5px !important;
  }
}
.react-datepicker__input-container input:active {
  border: 1px solid pink;
}

.react-datepicker__header {
  padding: 4px 0;
  /* background-color: #353B4B; */
  /* color: white; */
  font-family: 'Poppins', sans-serif;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #1f1d2b !important;
  font-weight: 500 !important;
}

.react-datepicker__day {
  /* color: white; */
  font-family: 'Poppins', sans-serif;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 1.3rem;
  line-height: 1.5rem;
}

textarea {
  resize: none;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

/* scrollbar */
.ReactModal__Content::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

.ReactModal__Content::-webkit-scrollbar-track {
  background: #353b4b; /* color of the tracking area */
}

.ReactModal__Content::-webkit-scrollbar-thumb {
  background-color: #8254f8; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.scroll-hidden {
  overflow-x: scroll; /* Allows horizontal scrolling */
  overflow-y: scroll; /* Prevents vertical scrolling */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.scroll-hidden::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.scroll-bar::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

.scroll-bar::-webkit-scrollbar-track {
  background: #353b4b; /* color of the tracking area */
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #8254f8; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.header-width {
  width: calc(100% - 62px);
}

.setting-header-width {
  width: calc(100% - 312px);
}

.Dropdown-control {
  background-color: #353b4b !important;
  border: none !important;
  border-radius: 0.25rem !important;
  color: white !important;
}

.Dropdown-menu {
  background-color: #353b4b !important;
  color: white;
  margin-top: 3px !important;
  border: 1px solid #8254f8 !important;
  border-radius: 0.25rem;
}

.Dropdown-option {
  color: white !important;
  font-size: 12px !important;
}

.Dropdown-option:hover {
  background-color: #8254f8 !important;
  color: white !important;
}

.Dropdown-option.is-selected {
  background-color: #8254f8 !important;
  color: white !important;
}

.grid {
  display: grid;
}

.col-span-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}
.outline-border {
  border-color: #8254f8;
}
.border-radius-50 {
  border-radius: 50%;
}

.mat-mdc-fab.mat-primary {
  background-color: #8254f8;
}

.preview-camera-video {
  width: 100%;
  height: 500px !important;
  aspect-ratio: 16 / 9;
  background-color: #000;
  border-radius: 15px;
}

.ng-star-inserted .controlskit {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 100% !important;
  background-color: transparent !important;
}

.ng-star-inserted .videokit {
  gap: 5px;
}
#participant-canvas {
  /* height: 600px !important; */
  border-radius: 15px;
  background-color: #c6b5ed !important;
  vertical-align: bottom;
}
.user-view {
  /* height: 500px !important; */
  margin: 0 auto;
  /* width: 90% !important; */
}

.user-view #participant-canvas {
  /* height: 500px !important; */
  margin: 0 auto;
  width: 100% !important;
}
#self-view-video {
  background-color: #c6b5ed !important;
  /* height: 100px !important; */
}

.videokit .user-view #participant-canvas {
  max-height: 500px !important;
}

.videokit {
  max-height: 632px;
}
/* zoom sdk */
div > .msg-bubble {
  background-color: #8254f8 !important;
}
/* zoom sdk */
.msger-send-btn {
  background-color: #8254f8 !important;
}

#preview {
  position: absolute;
  top: 20px;
  height: 600px;
}

#preview-camera-video {
  height: 450px !important;
}
/* #participants-screen-share-content-canvas {
  height: 500px;
} */

#videosdk-uitoolkit-gallery-canvas {
  background-color: #c6b5ed !important;
}

.avatar-content {
  /* background-color: #C6B5ED !important; */
}

.eVRFVp {
  top: 75% !important;
  height: 25%;
  display: flex !important;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}
.eVRFVp > button {
  background-color: #8254f8 !important;
  color: white !important;
}

#invitation-link-tooltip {
  font-size: 10px !important;
}

@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.shine-effect {
  position: relative;
  overflow: hidden;
}

.shine-effect::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 70%
  );
  animation: shine 1s forwards;
}

.hover:scale-105 {
  transition: transform 0.2s ease-in-out;
}

.canvasjs-chart-credit {
  display: none !important;
}

@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.animate-shine {
  animation: shine 3s linear infinite;
}

.seaweed {
  font-family: 'Seaweed Script' !important;
  /* font-weight: 400;
  font-style: normal; */
}

.markdown > * > * > * > * > *,
.markdown > * > * > * > *,
.markdown > * > * > *,
.markdown > * > *,
.markdown > * {
  margin-top: 15px;
  margin-bottom: 15px;
}

.markdown * {
  font-size: 14px;
}

.markdown strong {
  font-size: 16px;
}

/* hiding number inout right inc and dec options */
/* Hide spinners for modern browsers */
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinners for Firefox */
.no-spinners {
  -moz-appearance: textfield;
}

.ac-item {
  /* margin-top: 5px !important;
  margin-left: 5px !important;
  border-radius: 5px !important; */
}

.ac-item .tooltiptext {
  display: none !important;
}

.typewriter-text {
  display: flex;
  align-items: flex-start; /* Align items to the top */
  /* height: 100vh; Adjust height as needed */
  justify-content: start; /* Center horizontally */
  font-size: 50px; /* Change this to your desired font size */
  color: #ffffff; /* Change this to your desired color */
  font-family: Arial, sans-serif; /* Optional: change font family */
  display: none !important;
}

@keyframes pulse-slow {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}

.animate-pulse-slow {
  animation: pulse-slow 2s infinite ease-in-out;
}

.hover\:animation-none:hover {
  animation: none;
}

@media (min-width: 768px) {
  .typewriter-text {
    display: block !important;
  }
}
